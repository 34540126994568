import { default as _91slug_93c6ZDFEhnTrMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as indexHvLPvZvlQcMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as guidesn3NuHGbfG4Meta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as _91response_93470CnQmpOYMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as indexWZvW5CTessMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as schedule_45callbackNZ41WkkGdEMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as about_45us1U4C7DFtOiMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as callback58iGvPSuJZMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as mergekGFUULXk8eMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as comprehensivez2hjFNpDGeMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as index8TudEsA7tsMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91step_93Rsws6S4hvoMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as detailsBO2CWmcmLIMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as results0Lm4WlUIFIMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as indexL2qi0cSshfMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indexOq0mHOJEl0Meta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as collection_45noticeoxAOraCErLMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as complaintsfRym57bZh8Meta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45uswV75mz5IKfMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as _91slug_93L1f1Ufzc9LMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as index5bpxXJxjRyMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as _91slug_93BxOw8d4kMmMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as _91slug_93sb7T1U2Iv5Meta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as account_45settingsvb1p3KMIaOMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexkin9FB0CWVMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as index12PVnw8P5mMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as index56CxpoBJDPMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexdOQZpWI46fMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as indexuItvvY3RLwMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as additional_45details2NPPHxbzw9Meta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/vault/[[product]]/[vertical]/additional-details.vue?macro=true";
import { default as completionjPRq1wBB85Meta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/vault/[[product]]/[vertical]/completion.vue?macro=true";
import { default as detailsPPNXErFU1QMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/vault/[[product]]/details.vue?macro=true";
import { default as _91step_93pHfwGex3nZMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93XiSNexSIZeMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93Frgq8dRlgLMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/health-insurance/step/[step].vue?macro=true";
import { default as _91step_93CAdQyujrFDMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/home-loan/step/[step].vue?macro=true";
import { default as _91step_93DvNJJViesNMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/life-insurance/step/[step].vue?macro=true";
import { default as lic_45detailsNopeGNRSvkMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/vault/[[product]]/lic-details.vue?macro=true";
import { default as rebate42nDFmpOUrMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/vault/[[product]]/rebate.vue?macro=true";
import { default as digital_45eicnvCdXpxxJFMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as errorWgXSY4aS9fMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as thank_45you8xPjcjB9qkMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as index16dBrRSEloMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91step_93LWYaQKcgI1Meta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as detailsOXMsRD9yBBMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as thank_45youpsldXo2YaLMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as _91slug_93dr6Up2oPBwMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as indexYG3rIdbB08Meta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as _91author_93nmVTwZauwqMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as index1oLwd6WZvnMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as forgot_45passwordV8DNosWTFxMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as _91step_93BW5gJ3ZfG5Meta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as expiredwf5ng6Wbn2Meta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexfK3DUMJ7g5Meta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as indexZZ8NvHKmuXMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as error4XoMB6Lw0YMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indexm2WezxVG9wMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as live_45chat_45bsrzk4mgStIMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as live_45chat797LLR4BI9Meta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as indexhziuJyO1rTMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addglz2Q11EQBMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as signupeRvsW8elv6Meta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as post_45saleuh8JWzboesMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as indexNufKrNa6F9Meta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as _91step_93X5VC01AlSrMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as details3NPMxtCflaMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as indexc4JfUlTc1oMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as thank_45youcrDq1HhERvMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as indexZzLoWskCifMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as indexoIXyGX9kLpMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as index7UfuQ46uZGMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as index6BVQM4FmHkMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
import { default as _91step_93j0yzWHF8QsMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as _91step_93pYIX2CrTlDMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as confirmationyUV2ub5z0tMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as detailso2eOa5hHfgMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as indexeUgyzB05l9Meta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as quotes_45resultsMx7JkDVhIUMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as thank_45youQ1tcgkAFA9Meta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as _91type_93XdfSYlRjoqMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as indexgOlHZf1QlmMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as index3DtgCGtOPPMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as interest_45ratesNoppjj1mKYMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91step_93IHoh8weOODMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as detailsVCUW7Fe4y1Meta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as thank_45youNtCN1ehaUhMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as indexLwLa3sJckTMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/index.vue?macro=true";
import { default as confirmation4ulS7BpF9PMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as detailsVnbC8UEP05Meta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as indexASPn7BlqYYMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as indexg31zvrViejMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as add6m8Sv9NshSMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as thank_45youdpU1eWfR6ZMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as indexrcHLoAkvGoMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as successE6vvaTDMm3Meta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as _91step_934C8Wt2uUjJMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue?macro=true";
import { default as detailssjvasWuiVMMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as loginT7koF6RdtFMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/login.vue?macro=true";
import { default as _91slug_93yRzcGkOQcpMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as _91author_93UltxO7SVDTMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as indexAJxXua7EntMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as partnershipsY9CcoRxCh1Meta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as indexyQkYGag8GwMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as post_45sale_45thank_45you04Tma6A2GBMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as _91slug_93zZ2imiayJdMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as _91slug_93LlRXnDCXqgMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as _91slug_93p2UV7AYLtZMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as privacy_45policy0JVs0Thb0VMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as registerfYkKehnY88Meta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/register.vue?macro=true";
import { default as reset_45passwordywBtQbzJQ8Meta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as terms_45of_45useVYwYU3UzonMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as verifyYEtIXdmeJcMeta } from "/codebuild/output/src1750533777/src/packages/nuxt/pages/verify.vue?macro=true";
export default [
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: schedule_45callbackNZ41WkkGdEMeta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  },
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  }
]
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93L1f1Ufzc9LMeta || {},
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settingsvb1p3KMIaOMeta || {},
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexkin9FB0CWVMeta || {},
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: index12PVnw8P5mMeta || {},
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: index56CxpoBJDPMeta || {},
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: indexdOQZpWI46fMeta || {},
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    meta: indexuItvvY3RLwMeta || {},
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product?/:vertical()/additional-details",
    meta: additional_45details2NPPHxbzw9Meta || {},
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/vault/[[product]]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product?/:vertical()/completion",
    meta: completionjPRq1wBB85Meta || {},
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/vault/[[product]]/[vertical]/completion.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product?/details",
    meta: detailsPPNXErFU1QMeta || {},
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/vault/[[product]]/details.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product?/form/energy/step/:step()",
    meta: _91step_93pHfwGex3nZMeta || {},
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product?/form/generic/step/:step()",
    meta: _91step_93XiSNexSIZeMeta || {},
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/generic/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product?/form/health-insurance/step/:step()",
    meta: _91step_93Frgq8dRlgLMeta || {},
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/health-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product?/form/home-loan/step/:step()",
    meta: _91step_93CAdQyujrFDMeta || {},
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/home-loan/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product?/form/life-insurance/step/:step()",
    meta: _91step_93DvNJJViesNMeta || {},
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product?/lic-details",
    meta: lic_45detailsNopeGNRSvkMeta || {},
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/vault/[[product]]/lic-details.vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product?/rebate",
    meta: rebate42nDFmpOUrMeta || {},
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/dashboard/vault/[[product]]/rebate.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordV8DNosWTFxMeta || {},
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "life-insurance-quote-form-step",
    path: "/life-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginT7koF6RdtFMeta || {},
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerfYkKehnY88Meta || {},
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordywBtQbzJQ8Meta || {},
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src1750533777/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]