import _isEmpty from 'underscore/cjs/isEmpty.js'
import _uniq from 'underscore/cjs/uniq.js'
import _isObject from 'underscore/cjs/isObject.js'

const actions = {
  GENERATE_UTMS (payload) {
    if (!_isEmpty(payload)) {
      setSession('utms_query', JSON.stringify(payload))
    }

    const referrer = import.meta.client && (document.referrer || window.document.referrer)

    const _query = !_isEmpty(payload)
      ? payload
      : JSON.parse(getSession('utms_query'))
    
    const utms = Object.assign({}, {
      ...this.utms,
      ..._query
    })

    if (utms?.WBRAID) { 
      utms.wbraid = utms.WBRAID
      delete utms.WBRAID
    }

    /**
     * Remove uuid from utms
     * Having uuid in the utms causes bug when creating
     * a new lead.
     */
    if (utms?.uuid) { 
      delete utms.uuid
    }

    const utmSourceKeyword = {
      facebook: [
        'facebook.',
        'messenger.',
        'fbclid',
        'fb'
      ],
      google: [
        'google.',
        'gclid',
        'wbraid',
        'gbraid'
      ],
      yahoo: [
        'yahoo'
      ],
      duckduckgo: [
        'duckduckgo'
      ],
      bing: [
        'bing',
        'msclkid'
      ],
      outbrain: [
        'ob_click_id'
      ],
      taboola: [
        'click-id'
      ],
      twitter: [
        'twitter',
        't.co/'
      ],
      impact: [
        'IRClickID',
        'irclickid'
      ],
      'self-referral': [
        'lifeinsurancecomparison.',
        'alternativemedia.',
        'healthinsurancecomparison.',
        'homeloansaustralia.',
        'ratecomparison.',
        'hearingaidcomparison.',
        'mybroadbandcomparison.',
        'incomeshield.'
      ]
    }

    const sourceKeywordsArr = Object.keys(utmSourceKeyword)

    if (referrer) {
      /*
       * Reset utm_source and utm_medium
       * This is to remove initialized values set if there is no referrer
       */
      utms.utm_source = _query?.utm_source
      utms.utm_medium = _query?.utm_medium ?? utms.utm_medium ?? ''

      for (let i = 0; i < sourceKeywordsArr.length; i++) {
        const key = sourceKeywordsArr[i]
        const arr = utmSourceKeyword[key]

        // checks if referrer contains any of the keywords
        const inKeywords = arr.find(item => referrer.includes(item))

        if (inKeywords) {
          utms.utm_source = key
          
          break
        }
      }
    } else if (!referrer && !utms.utm_medium && !utms.utm_source) {
      utms.utm_source = 'direct'
      utms.utm_medium = 'organic'
    }

    /*
      * If utm_source is set in the query (_query) & utm_medium is not,
      * set utm_medium to empty string
     */
    if (
      _query?.utm_source &&
      _query?.utm_source !== 'direct' &&
      !_query?.utm_medium
    ) {
      utms.utm_medium = ''
    }

    /*
      * If utm_medium is set in the query (_query) & utm_source is not,
      * set utm_source to empty string
     */
    if (
      _query?.utm_medium &&
      _query?.utm_medium !== 'organic' &&
      !_query?.utm_source
    ) {
      utms.utm_source = ''
    } 

    if (utms.utm_source === 'impact') {
      utms.utm_medium = 'affiliate'
    }

    if (
      !utms?.utm_medium &&
      utms.utm_source &&
      ['google', 'bing', 'duckduckgo'].includes(utms.utm_source)
    ) {
      utms.utm_medium = 'organic'
    }

    // Remove duplicate utms and convert utm array to comma-separated string
    for (const utm in utms) {
      if (utms.hasOwnProperty(utm) && Array.isArray(utms[utm])) {
        utms[utm] = [...new Set(utms[utm])].join(',')
      }  
    }
    this.utms = utms
  },

  GENERATE_OPTIMIZELY_DATA () {
    const getOptimizelyData = () => {
      const optimizelyUtms = {
        optimizely_visitor_id: [],
        optimizely_active_experiment_ids: [],
        optimizely_variation_map: {}
      }

      const utmStorage = getSession('utms')
        ? JSON.parse(getSession('utms'))
        : {}

      Object.keys(optimizelyUtms).forEach(key => {
        if (
          utmStorage[key] !== undefined &&
          !_isEmpty(utmStorage[key])
        ) {
          optimizelyUtms[key] = utmStorage[key]
        }
      })

      const utms = this.utms

      Object.keys(this.optimizely_utms).forEach(key => {
        if (optimizelyUtms[key]) {
          if (
            this.optimizely_utms[key] instanceof Array &&
            optimizelyUtms[key] instanceof Array
          ) {
            optimizelyUtms[key].push(...this.optimizely_utms[key])
            optimizelyUtms[key] = _uniq(optimizelyUtms[key])
          } else if (
            _isObject(this.optimizely_utms[key]) &&
            !(this.optimizely_utms[key] instanceof Array)
          ) {
            const data = this.optimizely_utms[key]
            const data2 = optimizelyUtms[key] || {}

            optimizelyUtms[key] = {
              ...data2,
              ...data
            }
          }
        }
      })

      this.utms = {
        ...utms,
        ...optimizelyUtms
      }
    }

    if (import.meta.client) {
      window.onload = () => {
        getOptimizelyData()
      }

      getOptimizelyData()
    }
  }
}

export default actions
